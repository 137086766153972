import dynamic from 'next/dynamic';
const ModuleAutoStoreBrandCard = dynamic(
  () => import('../ModuleAutoStoreBrandCard')
);
const ModuleBasicCards = dynamic(() => import('../ModuleBasicCards'));
const ModuleCareerCards = dynamic(() => import('../ModuleCareerCards'));
const SanityModuleContent = dynamic(() => import('../ModuleContent'));
const ModuleCta = dynamic(() => import('../ModuleCta'));
const ModuleDetailedCards = dynamic(() => import('../ModuleDetailedCards'));
const ModuleFAQ = dynamic(() => import('../ModuleFAQ'));
const ModuleMainFAQ = dynamic(() => import('../ModuleMainFAQ'));
const ModuleMiniCalculator = dynamic(() => import('../ModuleMiniCalculator'));
const ModulePricingBreakdown = dynamic(
  () => import('../ModulePricingBreakdown')
);
const ModulePricingTiers = dynamic(() => import('../ModulePricingTiers'));
const ModuleRoiCalculator = dynamic(() => import('../ModuleRoiCalculator'));
const ModuleThreeColumns = dynamic(() => import('../ModuleThreeColumns'));

import { BaseModuleType } from '.';

const Module = <T extends BaseModuleType>(module: T) => {
  const type = module._type;

  switch (type) {
    case 'moduleCta':
      return <ModuleCta data={module} />;
    case 'moduleCareerCards':
      return <ModuleCareerCards data={module} />;
    case 'moduleContent':
      return <SanityModuleContent data={module} />;
    case 'moduleDetailedCards':
      return <ModuleDetailedCards data={module} />;
    case 'moduleRoiCalculator':
      return <ModuleRoiCalculator data={module} />;
    case 'moduleThreeColumns':
      return <ModuleThreeColumns data={module} />;
    case 'moduleMiniCalculator':
      return <ModuleMiniCalculator data={module} />;
    case 'moduleBasicCards':
      return <ModuleBasicCards data={module} />;
    case 'moduleFAQ':
      return <ModuleFAQ data={module} />;
    case 'moduleMainFAQ':
      return <ModuleMainFAQ data={module} />;
    case 'modulePricingBreakdown':
      return <ModulePricingBreakdown data={module} />;
    case 'modulePricingTiers':
      return <ModulePricingTiers />;
    case 'moduleAutoStoreBrandCard':
      return <ModuleAutoStoreBrandCard data={module} />;
    default:
      return null;
  }
};

export default Module;
