import ModuleHero from '@components/website/modules/ModuleHero';
import Modules from '@components/website/modules/Modules';
import SiteLayout from '@components/website/SiteLayout';
import { setVariant } from '@helpers/gtm';
import { getClient } from '@sanity/sanity.client';
import { queryPageDataWithHero, querySiteFooter } from '@sanity/sanity.queries';
import { PageHome, PageVariantHome } from '@sanity/sanity.types';
import { ModuleHeroType, SiteFooterType } from '@types';
import { GetStaticProps, NextPage } from 'next';
import { lazy, useEffect } from 'react';
import PreviewProvider from 'src/preview/PreviewProvider';

import { SANITY_TTL } from '../constants';
import { getFlag } from '../launch-darkly/launchDarkly.helpers';
import { HomePageFlagTypes } from '../launch-darkly/launchDarkly.types';

const PreviewPage = lazy(() => import('../preview/PagePreview'));

const PAGE_NAME = 'pageHome';
const VARIANT_PAGE_NAME = 'pageVariantHome';

type PageType = PageVariantHome | PageHome;

interface Props {
  pageData: Omit<PageType, 'hero'> & {
    hero: ModuleHeroType;
  };
  siteFooter: SiteFooterType;
  preview?: boolean;
  previewToken?: string | null;
  variant?: boolean;
}

export const PageContent = ({ pageData, siteFooter, variant }: Props) => {
  const { hero, modules, seo } = pageData;

  useEffect(() => {
    setVariant(variant ? 'B_variant' : 'A_variant');
  }, [variant]);

  return (
    <SiteLayout seoMeta={seo} siteFooter={siteFooter}>
      <ModuleHero {...hero} />
      <Modules modules={modules} />
    </SiteLayout>
  );
};

const Page: NextPage<Props> = ({
  preview,
  previewToken,
  pageData,
  siteFooter,
  variant
}) => {
  if (preview) {
    return (
      <PreviewProvider previewToken={previewToken}>
        <PreviewPage
          page={variant ? VARIANT_PAGE_NAME : PAGE_NAME}
          pageData={pageData}
          siteFooter={siteFooter}
        />
      </PreviewProvider>
    );
  }
  return (
    <PageContent
      variant={variant}
      pageData={pageData}
      siteFooter={siteFooter}
    />
  );
};

export const getStaticProps: GetStaticProps = async ({
  preview = false,
  previewData
}) => {
  const previewToken = preview ? process.env.SANITY_READ_TOKEN : undefined;
  if (preview && previewToken === undefined) {
    throw new Error(
      'Preview mode is active, but SANITY_READ_TOKEN is not set in environment variables'
    );
  }
  const client = getClient(previewToken);

  const flag: HomePageFlagTypes = await getFlag('homepage');

  const previewVariant =
    (typeof previewData === 'object' &&
      'variant' in previewData &&
      previewData?.variant === 'true') ??
    false;

  const variant = preview ? previewVariant : flag === 'variant';

  const { pageData } = await client.fetch(
    queryPageDataWithHero(variant ? VARIANT_PAGE_NAME : PAGE_NAME)
  );

  const { siteFooter } = await client.fetch(querySiteFooter);

  return {
    props: {
      preview,
      previewToken: previewToken ?? null,
      variant: variant,
      pageData,
      siteFooter
    },
    revalidate: SANITY_TTL
  };
};

export default Page;
