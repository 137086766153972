import cx from 'classnames';
import { forwardRef } from 'react';
import { ReactNode } from 'react';

import styles from './ModuleSection.module.scss';

interface WrapProps {
  id?: string;
  className?: string;
  hasGrayBg?: boolean;
  children?: ReactNode;
}

const SectionWrap = forwardRef<HTMLElement, WrapProps>((props, ref) => {
  const { hasGrayBg, children, className, id } = props;

  return (
    <section
      id={id}
      ref={ref}
      className={cx(styles.wrap, hasGrayBg && styles['bg-gray'], className)}
    >
      {children}
    </section>
  );
});

SectionWrap.displayName = 'SectionWrap';

export default SectionWrap;
