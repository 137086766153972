import { getClient } from '@sanity/sanity.client';
import { LiveQueryProvider } from 'next-sanity/preview';
import { useMemo } from 'react';

export default function PreviewProvider({
  children,
  previewToken
}: {
  children: React.ReactNode;
  previewToken?: string | null;
}) {
  const client = useMemo(
    () => getClient(previewToken ?? undefined),
    [previewToken]
  );
  return <LiveQueryProvider client={client}>{children}</LiveQueryProvider>;
}
