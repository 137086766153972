import cx from 'classnames';

import Module from './Module';
import styles from './Modules.module.scss';

export interface BaseModuleType {
  _type: string;
  _key: string;
}

interface ModulesProps<T> {
  modules: T | undefined;
  withComponentsNav?: boolean;
}

const Modules = <T extends BaseModuleType[] | undefined>({
  modules,
  withComponentsNav
}: ModulesProps<T>) => {
  if (!modules) return null;

  return (
    <div
      className={cx(styles.wrap, { [styles.components]: withComponentsNav })}
    >
      {modules.map((module, key) => {
        return <Module key={key} {...module} />;
      })}
    </div>
  );
};

export default Modules;
